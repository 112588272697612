import React, { useEffect } from 'react';
import styled from 'styled-components';
import withThankyouWrapper from '../../components/ThankyouPages/withThankyouWrapper';
import sendRequest from '../../utils/forms/API/sendRequest';
import storage from '../../utils/storage';

const IntroBlock = styled.div`
  box-sizing: border-box;
  // background: var(--primaryColor);
  color: #fff;
  padding: 10px;
  margin: 0 auto;
  text-align: right;
  p {
    margin: 0;
  }

  a {
    color: #fff;
    text-decoration: none;
  }
`;
const TyContainer = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;0,9..40,1000;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900;1,9..40,1000&display=swap');
  --primaryColor: #0d305d;
  --backgroundGrey: #f5f5f9;
  --penFedGreen: #0dae1d;
  --headerBackground: #fffbe8;

  font-family: 'DM Sans', sans-serif;
  width: 100%;
  margin: auto;
  max-width: 1080px;

  img {
    margin: 0;
  }

  ul,
  ol {
    margin-bottom: 0;
  }
  .headlineText {
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 40px;
    font-weight: 500;
    padding: 20px 0 0;
    width: 100%;

    @media(max-width: 501px) {
      font-size 16px;
    }
  }
  .happyLogoContainer {
    width: 100%;
    max-width: 60px;
    img {
      width: 100%;
      height: auto
    }
    @media(max-width: 501px) {
      max-width: 22px;
      margin-right: 5px
    }
  }
  li {
    margin: 0 0 10px 0;
    line-height: 1.25;
    margin-bottom: 20px;
    &.checkmark {
      list-style-type: none;
      position: relative;
      :before {
        content: '';
        background: url('https://s3-us-west-1.amazonaws.com/offers.printfingertech.net/images/circle-check.png')
          0 50% / contain no-repeat;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 0;
        left: -22px;
      }
    }
  }
  .mainContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    gap: 40px;

    @media(max-width: 501px) {
      flex-direction: column;
      margin-top: 30px;
      margin-left: 0;
    }
  }
  .aside {
    width: 65%;
    font-size: clamp(20px, 3vw, 28px);

    @media(max-width: 501px) {
      width: 100%;

    }
  }
  .bulletPoints {
    font-size: clamp(18px, 3vw, 18px);
    li, p {
      font-size: 16px;
      font-weight: 500
    }
  }
  .hero {
    padding: 0 10px;
  }
  .onestep {
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 18px;
    font-weight: 500;

    @media(max-width: 501px) {
      padding: 20px; 
      font-size: 16px;
      margin-bottom: 0;
    }
  }

  .featuredText {
    font-weight: bold;
    margin-bottom: 0;
  }
  .greenText {
    color: var(--penFedGreen);
  }

  .logoContainer {
    width: calc(100% - 20px);
    max-width: 350px;

    img {
      width: 100%;
      height: auto
    }

    @media(max-width: 501px) {
      max-width: 300px;
    }
  }
  .bottomMessage {
    background: var(--backgroundGrey);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    border-radius: 10px;
    margin: 20px auto;
    gap 20px;
  }
  .marketText {
    width: 90%;
    margin-bottom: 0;
  }
  .fastText{
    font-weight: 600;
  }
  .banner {
    width: 100%;
    background: var(--primaryColor);
    color: #fff;
    text-align: center;
    padding: 10px;
  }
  .expertText {
    background: var(--headerBackground);
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    width: 75%;
    border-radius: 10px;
    font-size: 14px;
    text-align: center;
    margin: 20px auto;
    @media(max-width: 501px) {
      width: 100%;
      padding: 20px;
      font-size: 12px;
      margin: 10px auto;
    }
  }
  .bannerText {
    font-size: clamp(20px, 3.5vw, 42px);
    font-weight: 400;
    &.subtext {
      font-size: clamp(20px, 2.5vw, 32px);
    }

    @media(max-width: 501px) {
      font-weight: 600;
    }
  }
  a {
    text-decoration: none;
  }
  .content {
    width: calc(100% - 40px);
    max-width: 960px;
    margin: 0 auto;
    &.split {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      justify-items: center;
      row-gap: 40px;
    }
  }
  .container {
    background: var(--backgroundGrey);
    padding: 20px 0;
  }
  .button {
    background: var(--penFedGreen);
    color: #fff;
    padding: 0px 40px;
    font-size: clamp(20px, 3vw, 28px);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: -10px 0 0 0;
    font-weight: 600;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
    transition: all 0.25s ease-in-out;
    :hover {
      transform: translate3d(2px, 2px, 0);
      box-shadow: none;
    }
    @media (max-width: 500px) {
      margin-bottom: 40px;
    }
  }
  .cta {
    margin-top: 40px;
  }
  .clickToCall .phoneText {
    margin-top: 15px;
    color: #333333;
  }
  .iconContainer {
    width: calc(100% - 20px);
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 20px;
  }
  .icon {
    width: 100%;
    text-align: center;
    svg {
      width: 60px;
    }
  }
  .footer {
    display: flex;
    justify-content: center;
    width: calc(100% - 20px);
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px 20px 40px 20px;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    bottom-border: thin black;
  }
  .bottomAside {
    width: 80%;
  }
  .noticeText {
    font-weight: 600;
  }
  .bottomIcon {
    height: 70px;
  }
  .bottomContainer {
    display: flex;
    width: 100%;
    margin: 0;
    gap: 20px;
  }
  .eqImage {
    height: 100px;
  }
  .bottomImage {
    padding-top: 20px;
    width: 100%;
    display: flex;
    margin-top: 50px;
    justify-content: center;
    border-top: 1px solid rgb(203 214 223);
  }

  .bottomImageContainer {
    width: 100%;
    max-width: 80px;
    margin: 0 auto;

    img {
      width: 100%;
      height: auto;
    }
  }
`;

const EchmThankyou = () => {
  useEffect(() => {
    const url = 'https://external.printfinger.tech:7106/api/GenericProxy';
    const getStorage = storage('session', 'get', 'jtkNetFd');
    if (getStorage) {
      const formData = JSON.parse(getStorage);
      const data = {
        ClientName: 'RebelIq',
        Url: 'https://internalapi.printfinger.tech:7150/api/rebelIq',
        Payload: {
          vertical: 'Mortgage',
          sub_vertical: 'Purchase',
          city: formData.City,
          state: formData.State,
          zip_code: formData.ZipCode,
          unique_id: formData.UUID,
        },
      };
      const successFunction = async response => {
        if (response?._links?.self?.href) {
          window.open(response?._links?.self?.href, '_blank');
        }
      };
      sendRequest(data, url, successFunction, 'POST');
    }
  }, []);

  return (
    <>
      <TyContainer>
        <div className="hero">
          <div className="headlineText">
            <div className="happyLogoContainer">
              <img
                src="https://s3-us-west-1.amazonaws.com/offers.printfingertech.net/images/happy.png"
                alt="happy"
              />
            </div>
            <p className="featuredText">
              Congratulations:{' '}
              <span className="greenText"> You've been Matched!</span>
            </p>
          </div>
          <p className="onestep">
            You're one step closer to purchasing a home with your new Mortgage
            Loan Officer.
          </p>
          <div className="expertText">
            A Mortgage Loan Officer assigned to you will contact you immediately
            to discuss your home-buying goals.
          </div>
          <div className="mainContainer">
            <div className="logoContainer">
              <img
                src="https://s3-us-west-1.amazonaws.com/offers.printfingertech.net/images/eriqty.png"
                alt="ERIQ"
              />
            </div>

            <section className="aside">
              <p className="bannerText">Here's what happens next</p>
              <section className="">
                <div className="bulletPoints">
                  <ul>
                    <li className="checkmark">
                      You'll receive an email, call, & text within the next 5
                      minutes*
                    </li>
                    <li className="checkmark">
                      You and the Mortgage Loan Officer will schedule a FREE,
                      personalized consultation at your convenience to answer
                      your questions
                    </li>
                    <li className="checkmark">
                      You and the Mortgage Loan officer will review your home
                      financing goals and match you with their loan options
                    </li>
                    <li className="checkmark">
                      You and the Mortgage Loan Officer take you through our
                      incredibly simple mortgage application process and lock
                      you into their available rate
                    </li>
                  </ul>
                  <p>* inside normal business hours</p>
                </div>
              </section>
            </section>
          </div>
        </div>
        <div className="bottomMessage">
          <img
            src="https://s3-us-west-1.amazonaws.com/offers.printfingertech.net/images/envalop.png"
            alt="envelope"
          />
          <p className="marketText">
            <span className="fastText">The market is moving fast.</span> Please
            be sure to check your email for the follow-up information you just
            received, so a Mortgage Loan Officer can get to work helping you
            achieve the dream of homeownership.
          </p>
        </div>
        <div className="bottomImage">
          <div className="bottomImageContainer">
            <img
              src="https://s3-us-west-1.amazonaws.com/offers.printfingertech.net/images/equalHousing.png"
              alt="eeq"
            />
          </div>
        </div>
      </TyContainer>
    </>
  );
};

export default withThankyouWrapper(EchmThankyou);
